<template>
  <div class="card-list">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
        <el-select
          v-model="tableParams.serviceId"
          placeholder="请选择服务内容"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in serviceList"
            :key="item.serviceId"
            :label="item.serviceName"
            :value="item.serviceId"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.cardType"
          placeholder="请选择卡券类型"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in ObjToOpt(cardTypeObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.cardStatus"
          placeholder="请选择卡券状态"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in ObjToOpt(cardStatusObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd(1)">新增卡券</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="排序" align="center" width="80">
        <template slot-scope="scope">
          <span>{{ scope.row.sortNum }}</span>
        </template>
      </el-table-column>
      <el-table-column label="卡券名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.cardName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="卡券类型" align="center">
        <template slot-scope="scope">
          <span>{{ cardTypeObj[scope.row.cardType] || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="卡券描述" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.cardType == 1 || scope.row.cardType == 4"
            >{{ scope.row.cardTypeNum }}次</span
          >
          <span v-if="scope.row.cardType == 3"
            >{{
              scope.row.minUsePrice > 0
                ? "满" + scope.row.minUsePrice
                : "无门槛"
            }}抵扣{{ scope.row.deductionPrice }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="适用范围" min-width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.rangeLimit || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="可用日期" min-width="150">
        <template slot-scope="scope" >
         <div v-if="scope.row.cardPricingType==1">
          <p v-for="(item, index) in scope.row.useDateList" :key="index">
            <span v-if="item.suitWriting">{{ item.suitWriting }};</span>
            <span v-if="item.rangeDate == '00:00-00:00'">全天</span>
            <span v-else>{{ item.rangeDate }}</span>
            <span>可用</span>
            <span v-if="item.continuousMaxHour && item.continuousMaxHour != '0'"
              >{{ item.continuousMaxHour }}小时</span
            >
          </p>
         </div>
         <div v-if="scope.row.cardPricingType==2||scope.row.cardPricingType==3">
          <p v-for="(item, index) in scope.row.useDateList" :key="index">
            <span v-if="item.continuousMaxHour && item.continuousMaxHour != '0'"
              >{{'该卡券最多连续使用'+item.continuousMaxHour+(scope.row.cardPricingType==2?'天':'个月')}}</span
            >
          
          </p>
         </div>
        </template>
      
      </el-table-column>
      <el-table-column label="有效期至" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.availabilityDate || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="价格 / 划线价格" align="center">
        <template slot-scope="scope">
          <span>￥{{ scope.row.preferentialPrice || "0" }}</span>
          <span>&nbsp;/&nbsp;</span>
          <s>￥{{ scope.row.originalPrice || "0" }}</s>
        </template>
      </el-table-column>
      <el-table-column label="卡券状态" align="center">
        <template slot-scope="scope">
          <span :style="{ color: cardStatusColor[scope.row.cardStatus] }">{{
            cardStatusObj[scope.row.cardStatus] || "-"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" min-width="140">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.cardStatus == 0"
            type="text"
            size="medium"
            @click.stop="onAdd(2, scope.row)"
            >编辑</el-button
          >
          <el-button type="text" size="medium" @click.stop="onSort(scope.row)"
            >排序</el-button
          >
          <el-button
            type="text"
            size="medium"
            @click.stop="onUpDown(scope.row, 1)"
            v-if="!scope.row.cardStatus && scope.row.cardType != 4"
            >上架</el-button
          >
          <el-button
            type="text"
            size="medium"
            @click="goConfirmDeleteCard(scope.row)"
            v-if="!scope.row.cardStatus"
            >删除</el-button
          >
          <el-button
            type="text"
            size="medium"
            @click.stop="onUpDown(scope.row, 0)"
            v-if="scope.row.cardStatus"
            >下架</el-button
          >
          <GiveCard :row="scope.row"></GiveCard>
          <OperateList :cardId="scope.row.cardId"></OperateList>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </section>
  </div>
</template>

<script>
import {
  getTableList,
  getTableSort,
  getTableUpDown,
  goDeleteCard,
} from "@/api/card/card";
import { getServiceList } from "@/api/resource/room";
import { ObjToOpt } from "@/utils/utils";
import { cardTypeObj, cardStatusObj, cardStatusColor } from "@/db/objs";
import OperateList from "./child/OperateList";
import GiveCard from "./child/GiveCard";
export default {
  components: { OperateList, GiveCard },
  data() {
    return {
      ObjToOpt,
      cardTypeObj,
      cardStatusObj,
      cardStatusColor,
      serviceList: [],
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [], //表格数据
      tableParams: {
        page: 1,
        size: 10,
        serviceId: "", //服务内容
        cardType: "", //卡券类型
        cardStatus: "", //卡券状态
        merchantId: "", //商户ID
        shopId: "", //店铺ID
      },
      // from表单
      params: {
        merchantId: "", //商户ID
        shopId: "", //店铺ID
      },
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
      this.params.merchantId = JSON.parse(merchantShopId)[0];
      this.params.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getTableList(); // 【请求】表格数据
    this.getServiceList(); // 【请求】服务列表
  },
  methods: {
    // 删除卡券
    goDeleteCard(cardId) {
      goDeleteCard({ cardId }).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getTableList();
        }
      });
    },
    // 二次确认删除卡券
    goConfirmDeleteCard(row) {
      let cardId = row.cardId;
      this.$confirm("请确定是否删除卡券?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 接口
          this.goDeleteCard(cardId);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 【请求】表格数据
    getTableList() {
      let data = this.tableParams;
      getTableList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】服务列表
    getServiceList() {
      let data = {
        shopId: this.tableParams.shopId,
      };
      getServiceList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.serviceList = res.data;
        }
      });
    },

    // 【请求】表格排序
    getTableSort(data) {
      getTableSort(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getTableList();
        }
      });
    },

    // 【请求】表格上下架
    getTableUpDown(data) {
      getTableUpDown(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getTableList();
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getTableList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getTableList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】编辑
    onAdd(type, row) {
      //  1新增 2编辑
      if (type == 1) {
        this.$router.push("/card/cardEdit");
      } else {
        this.$router.push({
          path: "/card/cardEdit?cardId=" + row.cardId,
        });
      }
    },

    // 【监听】排序
    onSort(row) {
      this.$prompt("请填写序号", "排序", {
        confirmButtonText: "确定",
        inputPattern: /^[0-9]\d*$/,
        inputErrorMessage: "请填写正整数",
        showCancelButton: false, //取消按钮
        closeOnClickModal: false, //点击遮罩
        inputValue: row.sortNum, //初始值
      }).then(({ value }) => {
        let data = {
          cardId: row.cardId,
          sortNum: value,
          merchantId: this.tableParams.merchantId, //商户ID
          shopId: this.tableParams.shopId, //商户ID
        };
        this.getTableSort(data);
      });
    },

    // 【监听】卡券上下架
    onUpDown(row, cardStatus) {
      let text = cardStatus ? "上架" : "下架";
      this.$confirm(`你确定要${text}该卡券吗?`, text, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        let data = {
          cardId: row.cardId,
          cardStatus: cardStatus,
          merchantId: this.tableParams.merchantId, //商户ID
          shopId: this.tableParams.shopId, //商户ID
        };
        this.getTableUpDown(data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-list {
  padding: 0.15rem;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}
</style>