<template>
  <div class="page">
    <el-button type="text" size="medium" @click.stop="onGiveCard">发放卡券</el-button>
    <!-- 编辑弹框 -->
    <el-dialog title="发放卡券" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="60%">
      <!-- 查詢 -->
      <section class="table-search">
        <div class="lable">
          <span><b>已选门店：</b>{{ shopName }}</span>
          <span><b>已选卡券：</b>{{ row.cardName }}</span>
        </div>
        <el-input v-model="tableParams.kw" placeholder="输入用户昵称或手机号搜索" @input="kwChange" clearable class="mr15"
          style="width: 2.2rem;" />
      </section>
      <!-- 表格 -->
      <el-table :data="tableData" border stripe :header-cell-style="{
        background: '#FAFAFA', color: '#000000'
      }">
        <el-table-column label="用户昵称丨手机号" align="center" min-width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.nickName || "-" }}</span>
            <span>&nbsp;|&nbsp;</span>
            <span>{{ scope.row.userMobile || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="首次访问时间" align="center" min-width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.addDt || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="最近访问时间" align="center" min-width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.updDt || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="累计完成订单时长" align="center" min-width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.total || "0" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="该用户已拥有卡券" min-width="180">
          <template slot-scope="scope">
            <div class="card-list">
              <el-tag style="margin: 0 .05rem .05rem 0;" v-for="(item, index) in scope.row.memberCardVoList"
                :key="index">{{ item.cardName
                }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="warning" plain size="medium" @click.stop="onGive(scope.row)">发放</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <section class="table-footer">
        <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
          :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="currentPage" @current-change="onCurrentPage">
        </el-pagination>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCardUserList,
  getGiveCard,
} from "@/api/user/user";
export default {
  name: "GiveCard",
  props: ['row'],
  data() {
    return {
      shopName: "",
      // 弹框
      editShow: false, //弹框开关
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        pageSize: 10,
        kw: "",//查询关键字
        merchantId: "",//商户ID
        shopId: "",//店铺ID
      },
    }
  },
  methods: {
    // 【请求】表格数据
    getCardUserList() {
      let data = this.tableParams;
      getCardUserList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】修改发放卡券
    getGiveCard(row) {
      let data = {
        shopId: row.shopId,
        userId: row.userId,
        shopUserId: row.shopUserId,
        goodsId: this.row.cardId,
      };
      getGiveCard(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.$message({
            message: res.message,
            type: "success",
          });
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getCardUserList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getCardUserList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },

    // 【监听】发放卡券
    onGiveCard() {
      let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
      if (merchantShopId) {
        this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
        this.tableParams.shopId = JSON.parse(merchantShopId)[1];
      }
      let merchantShopInfo = JSON.parse(window.localStorage.getItem('playOne-store-merchantShopInfo'));
      if (merchantShopInfo) {
        this.shopName = merchantShopInfo.shopName;
      }
      this.editShow = true;
      this.getCardUserList();// 【请求】表格数据
    },

    // 【监听】发放
    onGive(row) {
      let text = `你确定要给用户${row.nickName}发放卡券【${this.row.cardName}】吗？`
      this.$confirm(text, '发放卡券', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.getGiveCard(row)
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: inline-flex;
  margin-left: .1rem;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  margin-bottom: .15rem;
}

.lable {
  display: flex;
  height: .4rem;
  align-content: center;
  justify-content: space-between;

  span {
    margin-right: .15rem;
    line-height: .4rem;
  }
}

.card-list {
  max-height: 1rem;
  overflow: auto;
}
</style>